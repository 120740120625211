<template>
  <section class="userPolicies">
    <h2 class="userPolicies__title">Politicas de usuario</h2>
    <div class="polices-list">
      <UserPolicy v-for="policy in policesByUser" :key="policy._id" :policy="policy" :user="user" @change="onChange" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    UserPolicy: () => import("../permissions/UserPolicy.vue"),
  },
  computed: {
    ...mapGetters("policies", { policiesDb: "policiesDb" }),
    user: function() {
      return this.$store.state.user.dataUser;
    },
    policesByUser() {
      const roleUser = this.user.role;
      const validationByRole = {
        coordinator: (policyKey) => !["can-edit-profile", "acccess-to-novelties"].includes(policyKey),
        monitor: (policyKey) => policyKey !== "Access-reservations-CMS-fields",
        employee: (policyKey) => policyKey === "Access-reservations-CMS-fields",
        model: () => false,
      };
      return this.policiesDb.filter(({ key }) => key === "login-from-any-place" || validationByRole?.[roleUser]?.(key));
    },
  },
  methods: {
    onChange: function({ policy }) {
      const { status, key } = policy;
      const hasPolice = this.$store.state.user.dataUser.polices.hasOwnProperty(key);

      if (!status && hasPolice) {
        delete this.$store.state.user.dataUser.polices[key];
        this.$store.state.user.numTest++;
        return;
      }
      this.$store.state.user.dataUser.polices[key] = policy;
      this.$store.state.user.numTest++;
    },
  },
};
</script>

<style lang="scss">
.userPolicies {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
}
</style>
